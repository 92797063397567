<template>
  <div class="temporaryParkChargeRecordList-wrapper">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers"
          :hasOperateColumn="false">
      <template #headerSlot>
        <v-button text="返回"
                  v-if="fromTmpPark"
                  @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="订单编号"
                 v-model="searchParams.orderNo"></v-input>
        <v-input label="车牌号"
                 v-model="searchParams.carNum"></v-input>
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"
                   @onChange="communityChange"></v-select2>
        <v-select label="苑"
                  v-model="searchParams.blockName"
                  :options="blockNameOps"></v-select>
        <v-select2 label="所属公司"
                   v-model="searchParams.regionId"
                   v-bind="tenantParams"></v-select2>
        <v-select label="付款渠道"
                  v-model="searchParams.moneyType"
                  :options="moneyTypeOps"></v-select>
        <v-datepicker label="车辆进场时间段"
                      :startTime.sync="searchParams.startAccessTime"
                      :endTime.sync="searchParams.endAccessTime"
                      type="rangedatetimer"></v-datepicker>
        <v-datepicker label="订单支付时间段"
                      :startTime.sync="searchParams.startChargingTime"
                      :endTime.sync="searchParams.endChargingTime"
                      type="rangedatetimer"></v-datepicker>
        <v-select label="订单来源"
                  v-model="searchParams.orderSource"
                  :options="orderSourceOps"></v-select>
        <v-input label="操作人"
                 v-model="searchParams.operator"></v-input>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getOrgListURL
  // queryBlockNameFromCommunityURL
} from './api'
import {
  moneyTypeOps,
  moneyTypeMap,
  orderSourceOps,
  orderSourceMap
} from './map'
import { communityParams, tenantParams } from 'common/select2Params'
import { select2BlockURL } from 'common/api'

export default {
  name: 'TemporaryParkChargeRecordList',
  data () {
    return {
      moneyTypeOps,
      orderSourceOps,
      communityParams,
      tenantParams,
      blockNameOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      searchUrl: getListURL,
      exportUrl: exportListURL,
      orgParams: {
        searchUrl: getOrgListURL,
        response: {
          text: 'orgName'
        }
      },
      searchParams: {
        orderNo: '',
        carNum: '',
        communityId: '',
        blockName: undefined,
        regionId: '',
        moneyType: undefined,
        startAccessTime: '',
        endAccessTime: '',
        startChargingTime: '',
        endChargingTime: '',
        orderSource: undefined,
        operator: ''
      },
      headers: [
        {
          prop: 'orderNo',
          label: '订单编号',
          formatter (row, prop, index) {
            return index === 0 ? '合计' : row.orderNo
          }
        },
        {
          prop: 'sourceTypeText',
          label: '订单来源',
          formatter (row, prop, index) {
            return index === 0 ? '' : orderSourceMap[row.orderSource]
          }
        },
        {
          prop: 'carNum',
          label: '车牌号'
        },
        {
          prop: 'communityName',
          label: '所属项目',
          formatter (row, prop, index) {
            return index === 0 ? '' : row.communityName
          }
        },
        {
          prop: 'blockName',
          label: '所属苑',
          formatter (row, prop, index) {
            if (index === 0) {
              return ''
            } else {
              return row.blockName ? row.blockName : '-'
            }
          }
        },
        {
          prop: 'beginTime',
          label: '车辆进场时间'
        },
        {
          prop: 'parkingTime',
          label: '停车时长',
          formatter (row, prop, index) {
            return index === 0 ? '' : row.parkingTime
          }
        },
        {
          prop: 'dealCost',
          label: '应收金额（元）',
          align: 'right'
        },
        {
          prop: 'chargingTime',
          label: '订单支付完成时间'
        },
        {
          prop: 'moneyTypeText',
          label: '付款渠道',
          formatter (row, prop, index) {
            return index === 0 ? '' : row.moneyType === 100 ? '免费停车' : moneyTypeMap[row.moneyType]
          }
        },
        {
          prop: 'paidCost',
          label: '实收金额（元）',
          align: 'right'
        },
        {
          prop: 'couponMoney',
          label: '优惠金额（元）',
          align: 'right'
        },
        {
          prop: 'couponRemark',
          label: '优惠说明'
        },
        {
          prop: 'operator',
          label: '操作人'
        }
      ],
      fromTmpPark: false
    }
  },
  mounted () {
    let query = this.$route.query
    if (query.serialNumber) {
      this.searchParams = {
        orderNo: query.serialNumber,
        carNum: '',
        communityId: '',
        blockName: undefined,
        regionId: '',
        moneyType: undefined,
        startAccessTime: '',
        endAccessTime: '',
        startChargingTime: '',
        endChargingTime: '',
        orderSource: undefined,
        operator: ''
      }
      this.fromTmpPark = true
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'temporaryParkChargeRecordForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'temporaryParkChargeRecordForm',
        query: {
          id: row.id
        }
      })
    },
    communityChange (val) {
      if (!val) {
        this.blockNameOps = [
          {
            text: '全部',
            value: undefined
          }
        ]
        this.searchParams.blockName = undefined
      }
      val && val.id && this.$axios.get(select2BlockURL, {
        params: {
          communityId: val.id
        }
      }).then(res => {
        if (res.status === 100) {
          let ops = res.data.map(item => {
            return {
              text: item.name,
              value: item.name
            }
          })
          this.blockNameOps = [
            {
              text: '全部',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
