import { generateMapByOpts } from 'common/utils'

// 付款渠道
const moneyTypeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '线下',
    value: 0
  }, {
    text: '支付宝',
    value: 1
  }, {
    text: '微信',
    value: 12
  }, {
    text: '微信公众号',
    value: 13
  }, {
    text: '免费停车',
    value: 100
  }
]

const moneyTypeMap = generateMapByOpts(moneyTypeOps)
// 订单来源
const orderSourceOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '道闸',
    value: 0
  }, {
    text: '物管',
    value: 1
  }
]
const orderSourceMap = generateMapByOpts(orderSourceOps)

export {
  moneyTypeOps,
  moneyTypeMap,
  orderSourceOps,
  orderSourceMap
}
