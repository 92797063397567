// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}temporarypark/orders/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}temporarypark/orders/list/export`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 获取苑
// const queryBlockNameFromCommunityURL = `${API_CONFIG.baseURL}serverRoomAction!queryBlockNameFromCommunity.action`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL
  // queryBlockNameFromCommunityURL
}
